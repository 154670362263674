export const DAnames = {
  state: {
    db: 'db'
  },
  mutations: {
    CDB_STORE_DATA: 'CDB_STORE_DATA'
  },
  actions: {
    getData: 'getData'
  },
  getters: {}
}

export default DAnames
